import { navigate } from 'gatsby'

// convert array of object to object
export const convertArrayOfObjectToObject = (arr) => {
  const result = {}

  for (let i = 0; i < arr.length; i++) {
    Object.assign(result, arr[i])
  }

  return result
}

export function pagination(page, url) {
  return page === 1 ? navigate(`/${url}`) : navigate(`/${url}/${page}`)
}

export let PageSize = 12

const placeholder = '/img/default-bc-product.png'

export function dataToImages(images) {
  if (images?.length > 0) {
    return images.map((i, index) => {
      return {
        id: i?.['id'] || index,
        is_default: i?.['is_default'] || i?.['is_thumbnail'] || placeholder,
        url_standard: i?.['url_standard'] || i?.['url'] || placeholder,
        url_thumbnail: i?.['url_thumbnail'] || i?.['url'] || placeholder,
        url_zoom: i?.['url_zoom'] || i?.['url'] || placeholder,
      }
    })
  }
}

// Check if window is defined (so if in the browser or in node.js).
export const isBrowser = typeof window !== 'undefined'

export const scrollTo = (ref) => {
  const scrollTo = () => {
    if (ref.current) {
      return ref.current.scrollIntoView()
    }
  }
  scrollTo()

  return {
    scrollTo,
  }
}

export function sliceAfterMatch(str, delimiter) {
  const index = str.indexOf(delimiter)
  if (index === -1) {
    // If the delimiter is not found, return the original string
    return str
  } else {
    // Otherwise, return the part of the string after the delimiter
    return str.slice(index + delimiter.length)
  }
}

export const convertTimeStampToDate = (timestamp) => {
  const date = new Date(timestamp * 1000)

  const formattedDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  return formattedDate
}

export const LINE_ITEM_FIXED_LIMIT = 250
export const LINE_ITEM_THRESOLD_LIMIT = 240

export const LINE_ITEM_WARNING_MESSAGE = `You have reached the maximum line-item order limit for this order.
Please check out and start a new order. You will be provided a
1-time use coupon code for Free Shipping by email to place a
second order for order completion.`

export const LINE_ITEM_THRESOLD_LIMIT_MESSAGE = `You have reached the thresold limit of 240 line-item. You can add maximum 250 line-item`

export const productMicroData = (data, host) => {
  const product = data.allBigCommerceProducts.nodes[0]
  const brand = data.bigCommerceBrands

  const availabilityMapping = (arg) => {
    const options = {
      available: 'InStock',
      disable: 'Discontinued',
      preorder: 'PreOrder',
    }

    return options[arg]
  }

  return JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.name,
    url: `${host}${product.custom_url.url}`,
    ...(brand?.name && {
      brand: {
        '@type': 'Brand',
        url: `${host}${brand?.custom_url?.url}`,
        name: brand?.name,
      },
    }),
    description: product?.description,
    image: product.images[0]?.url_thumbnail,
    offers: {
      '@type': 'Offer',
      priceCurrency: 'USD',
      ...(product.price && {
        price: product.price,
      }),
      itemCondition: `https://schema.org/${product.condition}Condition`,
      availability: `https://schema.org/${availabilityMapping(
        product?.availability
      )}`,
      url: `${host}${product?.custom_url?.url}`,
    },
    ...(product?.reviews_count && {
      aggregateRating: {
        '@type': 'aggregateRating',
        bestRating: '5',
        ratingValue: product.reviews_rating_sum,
        ratingCount: product.reviews_count,
      },
    }),
  })
}

export const productBreadcrumbMicroData = (data, host) => {
  const product = data.allBigCommerceProducts.nodes[0]

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': host,
          name: 'Home',
        },
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': `${host}${product.custom_url.url}`,
          name: product.custom_url.url,
        },
      },
    ],
  })
}

export const productQtyMessages = (type, qty) => {
  let message = ''
  if (type === 'minimum') {
    message = 'Minimum "' + qty + '" Quantity required for checkout.'
  } else if (type === 'maximum') {
    message = 'Maximum "' + qty + '" Quantity allowed.'
  } else if (type === 'zero') {
    message =
      'This product has "0" inventory. Please remove this to proceed add to cart.'
  } else if (type === 'less-qty') {
    message =
      'This product has less quantity than required. so please note that only maximum available quantity will be added to the cart.'
  }
  return message
}

export const convertToMoney = (number) => {
  // const formattedNumber = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD", // Change to your desired currency code
  // }).format(number);
  const formattedNumber =
    number !== '' || number !== undefined
      ? number?.toLocaleString('en-US')
      : '-'
  return formattedNumber
}